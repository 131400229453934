<template>
    <div class="watermark-bg">
        <img
            v-if="moduleType === 'study'"
            @load="onCreateWatermarkBg"
            ref="watermarkBg"
            src="@/assets/images/course-video-bg.png"
        />
        <img
            v-if="moduleType === 'paper'"
            @load="onCreateWatermarkBg"
            ref="watermarkBg"
            src="@/assets/images/paper-bg.png"
        />
        <canvas ref="watermarkCanvas" class="canvas" width="140" height="40"></canvas>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    props: ['moduleType'],
    computed: { ...mapGetters(['userInfo']) },
    data() {
        return {}
    },
    methods: {
        onCreateWatermarkBg() {
            if (!this.userInfo) return
            const ctx = this.$refs.watermarkCanvas.getContext('2d')
            ctx.font = '24px Arial'
            ctx.fillText(`|${this.userInfo.id}|`, 0, 26)
            const textData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height).data
            // 再渲染图片
            const img = this.$refs.watermarkBg
            ctx.drawImage(img, 0, 0)
            const originalData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height)
            const newData = this.mergeData(textData, originalData)
            ctx.putImageData(newData, 0, 0)
            // 显示图片
            const watermarkData = this.$refs.watermarkCanvas.toDataURL('image/png')
            this.$emit('success', watermarkData)
        },
        // 合并信息
        mergeData(newData, originalData) {
            var oData = originalData.data
            var bit = 0
            var offset = 3

            for (var i = 0; i < oData.length; i++) {
                if (i % 4 == bit) {
                    // 只处理目标通道
                    if (newData[i + offset] === 0 && oData[i] % 2 === 1) {
                        // 没有信息的像素，该通道最低位置0，但不要越界
                        if (oData[i] === 255) {
                            oData[i] -= 1
                        } else {
                            oData[i] += 1
                        }
                    }
                }
            }
            return originalData
        },
    },
}
</script>
<style lang="scss" scoped>
.watermark-bg {
    display: none;
}
</style>
