<template>
    <div id="course" class="course">
        <Header />
        <div class="container content">
            <div class="empty" v-if="productList && productList.length === 0">
                <img src="@/assets/images/no-order.png" />
                <div class="desc">您还没有任何订单哦～</div>
            </div>
            <div v-else class="course-list" :style="`background-image: url(${watermarkData});`">
                <div class="course-item" @click="toStudyPage(item)" v-for="item in productList" :key="item.id">
                    <img :src="item.productInfo.bannerUrl" />
                    <div class="product-info">
                        <div class="product-name">
                            {{ item.productInfo.title }}
                        </div>
                        <div class="teacher">
                            {{ item.productInfo.courseTeacher ? item.productInfo.courseTeacher.name : '-' }}
                        </div>
                    </div>
                    <div class="br"></div>
                    <van-button class="study-btn">继续学习</van-button>
                    <div class="arrow">
                        <van-icon name="arrow" />
                    </div>
                </div>
            </div>
        </div>
        <Fotter />
        <WatermarkBg moduleType="paper" @success="onUpdateWatermarkData" />
    </div>
</template>
<script>
import { getUserOrders, getProductList, getOrderPackage } from '@/api/userApi.js'
import WatermarkBg from '@/components/WatermarkBg'
import Header from '@/components/layout/Header.vue'
import Fotter from '@/components/layout/Fotter.vue'

export default {
    name: 'course',
    data() {
        return {
            productList: null,
            watermarkData: '',
        }
    },
    components: { Header, Fotter, WatermarkBg },
    created() {
        this.getUserOrdersApi()
    },
    methods: {
        onUpdateWatermarkData(data) {
            this.watermarkData = data
        },
        async getUserOrdersApi() {
            this.$toast.loading({ duration: 0, message: 'Loading' })
            const res1 = await getUserOrders({ categoryNo: 'OFFICIAL' })
            if (res1.data.length !== 0) {
                const _productNos = []
                res1.data.forEach((item) => {
                    if (!_productNos.includes(item.productNo)) {
                        _productNos.push(item.productNo)
                    }
                })
                const res2 = await getProductList({ productNos: _productNos })
                res1.data.forEach((_order) => {
                    res2.data.forEach((_product) => {
                        if (_order.productNo === _product.productNo) {
                            _order.productInfo = _product
                        }
                    })
                })
                this.productList = res1.data
            }
            this.$toast.clear()
        },
        toStudyPage(item) {
            // 查询是否配置课程包
            console.log(item)
            getOrderPackage({ orderNo: item.orderNo }).then((res) => {
                console.log(res)
                if (res.data.length !== 0) {
                    this.$router.push(`/study/${item.orderNo}`)
                } else {
                    this.$dialog.alert({ message: '暂未配置课程包，请耐心等待老师配置' })
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
#course {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .content {
        flex-grow: 1;
    }
}
.course {
    width: 100%;
    background: $theme-bg-color;
    .container {
        margin: 0 auto;
        padding: 24px;
        width: $center-width;
    }
    .course-list {
        border-radius: 8px;
        padding: 0 48px;
        min-height: 400px;
        .course-item {
            padding: 36px 0;
            border-bottom: 1px solid #f7f7f7;
            display: flex;
            justify-content: space-between;
            align-items: center;
            > img {
                width: 160px;
                height: 90px;
                flex-shrink: 0;
                object-fit: cover;
            }
            .product-info {
                padding: 0 10px 0 28px;
                flex-grow: 1;
                height: 90px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .product-name {
                    font-weight: 700;
                    font-size: 16px;
                    color: #33312f;
                    line-height: 1.4em;
                    cursor: pointer;
                }
                .teacher {
                    height: 14px;
                    font-size: 14px;
                    line-height: 14px;
                    color: #66625e;
                    margin-bottom: 12px;
                }
            }
            .br {
                height: 80px;
                margin: 0 42px;
                width: 1px;
                background: #efefef;
            }
            .study-btn {
                flex-shrink: 0;
                background: $theme-color;
                border: none;
                width: 96px;
                height: 42px;
                border-radius: 4px;
                color: #ffffff;
            }
            .arrow {
                display: none;
            }
        }
    }
    .empty {
        border-radius: 8px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 100px 0;
        img {
            background: none;
            width: 320px;
            height: 219px;
        }
        .desc {
            font-size: 16px;
            font-weight: 700;
            color: #66625e;
            margin: 28px 0;
        }
    }
}

@media screen and (max-width: 980px) and (min-width: 720px) {
    .course {
        .container {
            padding: 24px;
            width: auto;
        }
    }
}

@media screen and (max-width: 720px) {
    .course {
        .container {
            padding: 0;
            width: 100%;
        }
        .course-list {
            border-radius: 0;
            padding: 0 24px;
            .course-item {
                padding: 24px 0;
                .product-info {
                    padding: 0 6px 0 16px;
                    .product-name {
                        font-size: 14px;
                    }
                    .teacher {
                        font-size: 12px;
                        margin-bottom: 8px;
                    }
                }
                .br {
                    display: none;
                }
                .study-btn {
                    display: none;
                }
                .arrow {
                    display: flex;
                    align-items: center;
                    display: block;
                    font-size: 20px;
                    color: #666;
                }
            }
        }
        .empty {
            padding: 80px 0;
            img {
                background: none;
                width: 280px;
                height: 191px;
            }
            .desc {
                font-size: 14px;
                margin: 18px 0;
            }
        }
    }
}
</style>
